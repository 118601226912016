// Translation file
import store from "./store";
const translations = {
  en: {
    login: "Login",
    register: "Register",
    menu: "Menu",
    filters: "Filters",
    notifications: "Notifications",
    calendar: "Calendar",
    account: "Account",
    profile: "Profile",
    settings: "Settings",
    contacts: "Contacts",
    changePassword: "Change Password",
    tutorial: "Tutorial",
    adminPanel: "Admin Panel",
    landingDemo: "Landing Demo",
    logIn: "Login",
    logout: "Logout",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    writeSomething: "Write something...",
    next: "Next",
    previous: "Previous",
    create: "Create",
    save: "Save",
    files: "Files",
    tasks: "Tasks",
    labels: "Labels",
    reminders: "Reminders",
    share: "Share",
    more: "More",
    details: "Details",
    activity: "Activity",
    delete: "Delete",
    edit: "Edit",
    userProfile: "User Profile",
    home: "Home",
    pinned: "Pinned",
    unpinned: "Unpinned",
    pin: "Pin",
    unpin: "Unpin",
    file: "File",
    reminder: "Reminder",
    task: "Task",
    sharedWith: "User access",
    invite: "Invite",
    sharedWithMe: "Shared with me",
    dateCreated: "Date created",
    lastUpdated: "Last updated",
    clearFilters: "Clear Filters",
    close: "Close",
    color: "Color",
    colors: "Colors",
    newest: "Newest",
    oldest: "Oldest",
    created: "Created",
    updated: "Updated",
    remindersOn: "Reminders on",
    appearance: "Appearance",
    language: "Language",
    selectTheme: "Select your preferred theme",
    selectLang: "Select your preferred language",
    permissions: "Permissions",
    selectLabels: "Select labels",
    selectSharedWith: "Select shared with",
    selectOwner: "Select owner",
    selectDateRange: "Select date range",
    recentlyUpdated: "Recently Updated",
    groupPinned: "Group pinned",
    filterPlaceholder: "Filter (exact word match)",
    attachments: "Attachments",
    has: "Has",
    doesNotHave: "Does not have",
    clear: "Clear",
    done: "Done",
    owner: "Owner",
    searchOrAddEmails: "Search or add emails...",
    permission: "Permission",
    guest: "Guest",
    guestTooltip:
      "This user is not registered and will not receive updates or notifications, but their personal share link allows them to see the latest changes.",
    copyShareLink: "copy share link",
    notShared: "Not shared with anyone",
    viewOnlyShareOptions: "View only share options",
    shareTo: "Share to",
    copyUrl: "Copy URL",
    copy: "Copy",
    copyEmbed:
      "Copy the embed code below and paste it into the HTML of your desired site.",
    copiedEmbed: "The public embed code has been successfully copied.",
    QRCode: "QR code",
    enterLabelName: "Enter label name",
    pickDateTime: "Pick date & time",
    pickPlace: "Pick place",
    repeats: "Repeats",
    never: "Never",
    after: "After",
    events: "events",
    dueDate: "Due date",
    processing: "Processing...",
    uploading: "Uploading...",
    loading: "Loading...",
    moderation: "Moderation",
    keywords: "Keywords",
    cancel: "Cancel",
    fileDetails: "File details",
    size: "Size",
    sortBy: "Sort By",
    unavailable: "Unavailable",
    enterKeywords: "Enter descriptive keywords",
    errorLoadingImage: "Error loading image",
    retry: "Retry",
    arrive: "Arrive",
    leave: "Leave",
    chooseLocation: "Choose a location",
    added: "added",
    from: "from",
    to: "to",
    on: "On",
    post: "post",
    replyTo: "Reply to",
    addComment: "Add a comment...",
    loginToComment: "Please login to post a comment.",
    createdNew: "created a new",
    newNote: "New note",
    noActivity: "No activty found.",
    comments: "Comments",
    noAlerts: "No alerts at this time",
    noInvites: "No pending invites",
    clickToView: "— Click to view —",
    secureNote: "Note Secured",
    embed: "Embed",
    aboutComments: {
      title: "About Comments",
      p1: `Comments you add to a note can be seen by others with access to
              that note. If the note is shared outside the app, your comments
              may be shared too.`,
      p2: `You're in control—delete your comments anytime within the app.`,
    },
    aboutActivity: {
      title: "About Activity",
      p1: `The note activity feed provides a detailed log of all changes made to a note, including edits, additions, and updates. These activities are visible to everyone with access to the note, ensuring transparency and collaboration. If the note is shared outside the app, the activity feed entries may also be shared, depending on the permissions set by the note owner.`,
    },
    aboutReminders: {
      title: "About Reminders",
      p1: `Reminders ensure you never miss something important by sending notifications based on a specific date and time or when arriving at or departing from a location. These reminders are shared with anyone who has access to the note.`,
    },
    aboutLabels: {
      title: "About Labels",
      p1: `Labels help you organize and categorize your notes, making it easier to find and manage related content. Labels are shared with anyone who has access to the note.`,
    },
    aboutSharing: {
      title: "About Sharing",
      p1: `Sharing allows you to collaborate by inviting others using their email or phone number. If the recipient is not registered, a personal share URL will be generated to grant them access.`,
      p2: "You can choose to grant users viewer or editor access and access can be revoked at any time.",
    },
    noComments: "No comments found.",
    noTasks: "No Tasks",
    noFiles: "No Files",
    noLabels: "No Tags",
    firstToComment: "Be the first to leave a comment!",
    laterToday: "Later today",
    tomorrow: "Tomorrow",
    nextWeek: "Next week",
    repeat: "Repeat",
    every: "Every",
    responsibleUser: "Responsible user",
    listItem: "List item...",
    taskDetails: "Task Details",
    addTask: "Add task",
    complete: "complete",
    reply: "Reply",
    like: "Like",
    viewer: "Viewer",
    editor: "Editor",
    shareNote: "Shared a note",
    yesContinue: "Yes, Continue",
    about: "About",
    publicUrlCopied: "Public view only url was copied successfully",
    fileType: "File type",
    noteSecurity: "Security",
    personalUrlCopied: (name) =>
      `${name} personal share url was copied successfully`,
    copiedToClip: "Copied to clipboard",
    confirmCollab: "Confirm Collaborator",
    confirmContact: `<p>This contact is not associated with a registered user.</p>
      <p>Adding this contact will generate a personal share link for them. They will be added to this note as a guest and will need this link to access the content.</p>
      <p class='font-bold'>Note: This contact will not receive live updates, but they can use their personal share URL at any time to view any changes.</p>
      <p>Would you like to continue?</p>
    `,
    showHideComplete: (hide) => (!hide ? "Hide complete" : "Show complete"),
    showHideReplies: (show, count) => `—
      ${show ? "Hide" : "Show"} ${count} ${count > 1 ? "replies" : "reply"} —`,
    fileCount: (files) =>
      `${files.length} ${files.length > 1 ? "files" : "file"}`,
    taskCount: (files) =>
      `${files.length} ${files.length > 1 ? "tasks" : "task"}`,
    uploadingFile: (currentFile) => `Uploading ${currentFile.name}...`,
    pageOf: (page, total) => {
      `Page ${page} of ${total}`;
    },
    stopwords: [
      "a",
      "an",
      "and",
      "are",
      "as",
      "at",
      "be",
      "by",
      "for",
      "from",
      "has",
      "he",
      "in",
      "is",
      "it",
      "its",
      "of",
      "on",
      "that",
      "the",
      "to",
      "was",
      "were",
      "will",
      "with",
      "you",
      "your",
      "this",
      "they",
      "but",
      "or",
      "if",
      "so",
      "not",
      "than",
      "then",
      "too",
      "all",
      "can",
      "just",
    ],
  },
  es: {
    login: "Iniciar sesión",
    register: "Registrarse",
    menu: "Menú",
    filters: "Filtros",
    notifications: "Notificaciones",
    calendar: "Calendario",
    account: "Cuenta",
    profile: "Perfil",
    settings: "Configuración",
    contacts: "Contactos",
    changePassword: "Cambiar Contraseña",
    tutorial: "Tutorial",
    adminPanel: "Panel de Administración",
    landingDemo: "Demostración de Inicio",
    logIn: "Iniciar Sesión",
    logout: "Cerrar Sesión",
    privacyPolicy: "Política de Privacidad",
    termsOfService: "Términos de Servicio",
    writeSomething: "Escribe algo...",
    next: "Siguiente",
    previous: "Anterior",
    create: "Crear",
    save: "Guardar",
    files: "Archivos",
    tasks: "Tareas",
    labels: "Etiquetas",
    reminders: "Recordatorios",
    share: "Compartir",
    more: "Más",
    details: "Detalles",
    activity: "Actividad",
    delete: "Eliminar",
    edit: "Editar",
    userProfile: "Perfil de Usuario",
    home: "Inicio",
    pinned: "Fijado",
    unpinned: "Sin Fijar",
    pin: "Fijar",
    unpin: "Desfijar",
    file: "Archivo",
    reminder: "Recordatorio",
    task: "Tarea",
    sharedWith: "User access",
    invite: "Invite",
    sharedWithMe: "Compartido conmigo",
    dateCreated: "Fecha de creación",
    lastUpdated: "Última actualización",
    clearFilters: "Limpiar Filtros",
    close: "Cerrar",
    color: "Color",
    colors: "Colores",
    newest: "Más reciente",
    oldest: "Más antiguo",
    created: "Creado",
    updated: "Actualizado",
    remindersOn: "Recordatorios en",
    appearance: "Apariencia",
    language: "Idioma",
    selectTheme: "Selecciona tu tema preferido",
    selectLang: "Selecciona tu idioma preferido",
    permissions: "Permisos",

    selectLabels: "Seleccionar etiquetas",
    selectOwner: "Seleccionar propietario",
    selectDateRange: "Seleccionar rango de fechas",
    selectSharedWith: "Seleccionar compartido con",

    recentlyUpdated: "Actualizado Recientemente",
    groupPinned: "Grupo fijado",
    filterPlaceholder: "Filtrar...",
    attachments: "Adjuntos",
    has: "Tiene",
    doesNotHave: "No tiene",
    clear: "Limpiar",
    done: "Hecho",
    owner: "Propietario",
    searchOrAddEmails: "Buscar o agregar correos electrónicos...",
    permission: "Permiso",
    guest: "Invitado",
    guestTooltip:
      "Este usuario no está registrado y no recibirá actualizaciones ni notificaciones, pero su enlace de compartición personal les permitirá ver los cambios más recientes.",
    copyShareLink: "Copiar enlace de compartición",
    notShared: "No compartido con nadie",
    viewOnlyShareOptions: "Opciones de solo visualización",
    shareTo: "Compartir con",
    copyUrl: "Copiar URL",
    copy: "Copiar",
    copyEmbed:
      "Copia el código de inserción a continuación y pégalo en el HTML de tu sitio deseado.",
    copiedEmbed: "El código de inserción público se ha copiado con éxito.",
    QRCode: "Código QR",
    enterLabelName: "Introducir nombre de etiqueta",
    pickDateTime: "Seleccionar fecha y hora",
    pickPlace: "Seleccionar lugar",
    repeats: "Repeticiones",
    never: "Nunca",
    after: "Después",
    events: "eventos",
    dueDate: "Fecha de vencimiento",
    processing: "Procesando...",
    uploading: "Subiendo...",
    loading: "Cargando...",
    moderation: "Moderación",
    keywords: "Palabras clave",
    cancel: "Cancelar",
    fileDetails: "Detalles del archivo",
    size: "Tamaño",
    sortBy: "Ordenar por",
    unavailable: "No disponible",
    enterKeywords: "Introduce palabras clave descriptivas",
    errorLoadingImage: "Error al cargar la imagen",
    retry: "Reintentar",
    arrive: "Llegar",
    leave: "Salir",
    chooseLocation: "Seleccionar ubicación",
    added: "agregado",
    from: "de",
    to: "a",
    on: "En",
    post: "publicar",
    replyTo: "Responder a",
    addComment: "Agregar un comentario...",
    loginToComment: "Por favor, inicia sesión para publicar un comentario.",
    createdNew: "creó un nuevo",
    newNote: "Nueva nota",
    noActivity: "No se encontró actividad.",
    comments: "Comentarios",
    noAlerts: "No hay alertas en este momento",
    noInvites: "No hay invitaciones pendientes",
    clickToView: "Haz clic para ver",
    secureNote: "Nota Segura",
    embed: "Incrustar",
    aboutComments: {
      title: "Acerca de los Comentarios",
      p1: `Los comentarios que agregues a una nota pueden ser vistos por otros con acceso a esa nota. Si la nota se comparte fuera de la aplicación, tus comentarios también pueden compartirse.`,
      p2: `Tienes el control: elimina tus comentarios en cualquier momento dentro de la aplicación.`,
    },
    aboutActivity: {
      title: "Acerca de la Actividad",
      p1: `El registro de actividad de la nota proporciona un detalle de todos los cambios realizados en una nota, incluyendo ediciones, adiciones y actualizaciones. Estas actividades son visibles para todos los que tienen acceso a la nota, garantizando transparencia y colaboración. Si la nota se comparte fuera de la aplicación, los registros de actividad también pueden ser compartidos, dependiendo de los permisos establecidos por el propietario.`,
    },
    aboutReminders: {
      title: "Acerca de los Recordatorios",
      p1: `Los recordatorios aseguran que nunca olvides algo importante enviando notificaciones basadas en una fecha y hora específica o al llegar o salir de un lugar. Estos recordatorios se comparten con todos los que tienen acceso a la nota.`,
    },
    aboutLabels: {
      title: "Acerca de las Etiquetas",
      p1: `Las etiquetas te ayudan a organizar y categorizar tus notas, facilitando encontrar y manejar contenido relacionado. Las etiquetas se comparten con todos los que tienen acceso a las notas etiquetadas.`,
    },
    aboutSharing: {
      title: "Acerca de Compartir",
      p1: `Compartir te permite colaborar invitando a otros usando su correo electrónico o número de teléfono. Si el destinatario no está registrado, se generará un enlace personal para otorgarles acceso.`,
      p2: "Puedes elegir otorgar acceso como visor o editor y puedes revocar el acceso en cualquier momento.",
    },
    noComments: "No se encontraron comentarios.",
    noTasks: "No hay tareas",
    noFiles: "No hay archivos",
    noLabels: "No hay etiquetas",
    firstToComment: "¡Sé el primero en dejar un comentario!",
    laterToday: "Más tarde hoy",
    tomorrow: "Mañana",
    nextWeek: "La próxima semana",
    repeat: "Repetir",
    every: "Cada",
    responsibleUser: "Usuario responsable",
    listItem: "Elemento de la lista...",
    taskDetails: "Detalles de la tarea",
    addTask: "Agregar tarea",
    complete: "completar",
    reply: "Responder",
    like: "Me gusta",
    viewer: "Visor",
    editor: "Editor",
    shareNote: "Compartió una nota",
    yesContinue: "Sí, Continuar",
    about: "Acerca de",
    publicUrlCopied: "El URL de solo vista pública se copió correctamente",
    fileType: "Tipo de archivo",
    noteSecurity: "Seguridad",
    personalUrlCopied: (name) =>
      `El URL personal de ${name} se copió correctamente`,
    copiedToClip: "Copiado al portapapeles",
    confirmCollab: "Confirmar Colaborador",
    confirmContact: `<p>Este contacto no está asociado con un usuario registrado.</p>
      <p>Agregar este contacto generará un enlace personal para compartir. Se agregará a esta nota como invitado y necesitará este enlace para acceder al contenido.</p>
      <p class='font-bold'>Nota: Este contacto no recibirá actualizaciones en vivo, pero podrá usar su enlace personal en cualquier momento para ver los cambios.</p>
      <p>¿Te gustaría continuar?</p>
    `,
    stopwords: [
      "un",
      "una",
      "y",
      "son",
      "como",
      "en",
      "ser",
      "por",
      "para",
      "de",
      "ha",
      "él",
      "en",
      "es",
      "eso",
      "su",
      "de",
      "en",
      "que",
      "el",
      "a",
      "fue",
      "fueron",
      "será",
      "con",
      "tú",
      "tu",
      "esto",
      "ellos",
      "pero",
      "o",
      "si",
      "entonces",
      "no",
      "que",
      "entonces",
      "también",
      "todo",
      "puede",
      "solo",
    ],
    showHideComplete: (hide) =>
      !hide ? "Ocultar completados" : "Mostrar completados",
    showHideReplies: (show, count) =>
      `— ${show ? "Ocultar" : "Mostrar"} ${count} ${
        count > 1 ? "respuestas" : "respuesta"
      } —`,
    fileCount: (files) =>
      `${files.length} ${files.length > 1 ? "archivos" : "archivo"}`,
    taskCount: (files) =>
      `${files.length} ${files.length > 1 ? "tareas" : "tarea"}`,
    uploadingFile: (currentFile) => `Subiendo ${currentFile.name}...`,
    pageOf: (page, total) => `Página ${page} de ${total}`,
  },
};

export function getTranslations() {
  const lang = store.state?.preferences?.lang ?? "en"; // Default to English if no lang is set
  return translations[lang] || translations.en;
}
